/**
 * Buttons
 */

.btn--default {
  @include font-size(16px);

  display: inline-block;
  position: relative;
  padding: 0.5rem 1rem;
  min-width: 7.5rem;
  margin: auto;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid transparent;
  transition: $transition-all-faster;
  overflow: hidden;
  z-index: 2;
  text-align: center;

  &:hover {
    border: 1px solid transparent;
  }
}

//Primary
.btn--primary-secondary {
  color: white;
  border-color: theme-color("primary");
  background-color: theme-color("primary");

  &:hover {
    color: white;
    border-color: theme-color("secondary");
    background-color: theme-color("secondary");
  }
}

//Secondary / Primary
.btn--secondary-primary {
  color: white;
  border-color: theme-color("secondary");
  background-color: theme-color("secondary");

  &:hover {
    color: white;
    border-color: theme-color("primary");
    background-color: theme-color("primary");
  }
}

//Secondary / White
.btn--secondary-white {
  color: white;
  border-color: theme-color("secondary");
  background-color: theme-color("secondary");

  &:hover {
    color: theme-color("primary");
    border-color: white;
    background-color: white;
  }
}

//Black
.btn--black-white {
  color: white;
  border-color: black;
  background-color: black;

  &:hover {
    color: white;
    border-color: theme-color("secondary");
    background-color: theme-color("secondary");
  }
}

//White
.btn--white {
  color: black;
  border-color: white;
  background-color: white;

  &:hover {
    color: white;
    border-color: theme-color("secondary");
    background-color: theme-color("secondary");
  }
}

//White Outline
.btn--white-outline {
  color: white;
  border-color: white;

  &:hover {
    color: white;
    border-color: theme-color("secondary");
    background-color: theme-color("secondary");
  }
}

//Gray
.btn--gray {
  color: theme-color("gray-dark");
  border-color: theme-color("gray-dark");

  &:hover {
    color: white;
    border-color: theme-color("orange");
  }
}

//Orange
//.btn--orange {
//  color: theme-color("orange");
//  border-color: theme-color("orange");
//
//  &:hover {
//    color: white;
//    border-color: theme-color("orange");
//  }
//}
