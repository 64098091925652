/********
* Grid
*********/

.container-grid {
  display: grid;
  grid-gap: 0;
  grid-template-rows: repeat(1, 1fr);
  grid-auto-flow: dense;

  /* 1 col */
  &.-col--1 {
    @include grid-template-cols(1);
  }

  /* 2 cols */
  &.-col--2 {
    @include grid-template-cols(2);
  }

  /* 3 cols */
  &.-col--3 {
    @include grid-template-cols(3);
  }

  /* 4 cols */
  &.-col--4 {
    @include grid-template-cols(4);
  }

  &.-col-lg--4 {
    @include media-breakpoint-up(lg) {
      @include grid-template-cols(4);
    }
  }
}

.grid-item--width2 {
  @include grid-item("width", 2);
}

.grid-item-md--width2 {
  @include media-breakpoint-up(md) {
    @include grid-item("width", 2);
  }
}

.grid-item--height2 {
  @include grid-item("height", 2);
}
