/**********
* Sections
***********/
.section {
  position: relative;
  z-index: 1;
}

.section__inner {
  padding-top: $section-spacing-mobile;
  padding-bottom: $section-spacing-mobile;

  @include media-breakpoint-up(md) {
    padding-top: $section-spacing-tablet;
    padding-bottom: $section-spacing-tablet;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $section-spacing-desktop;
    padding-bottom: $section-spacing-desktop;
  }
}

.section__content {
  z-index: 2;
}

/**
 * Section Padding
 */
.section-pt--066 {
  padding-top: calc(#{$section-spacing-mobile / 3} * 2);

  @include media-breakpoint-up(md) {
    padding-top: calc(#{$section-spacing-tablet / 3} * 2);
  }

  @include media-breakpoint-up(lg) {
    padding-top: calc(#{$section-spacing-desktop / 3} * 2);
  }
}

.section-pt {
  padding-top: $section-spacing-mobile;

  @include media-breakpoint-up(md) {
    padding-top: $section-spacing-tablet;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $section-spacing-desktop;
  }
}

.section-pt-md {
  @include media-breakpoint-up(md) {
    padding-top: $section-spacing-tablet !important;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $section-spacing-desktop !important;
  }
}

.section-pb {
  padding-bottom: $section-spacing-mobile;

  @include media-breakpoint-up(md) {
    padding-bottom: $section-spacing-tablet;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $section-spacing-desktop;
  }
}

.section-pb--050 {
  padding-bottom: calc(#{$section-spacing-mobile / 2});

  @include media-breakpoint-up(md) {
    padding-bottom: calc(#{$section-spacing-tablet / 2});
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: calc(#{$section-spacing-desktop / 2});
  }
}

.section-pb--066 {
  padding-bottom: calc(#{$section-spacing-mobile / 3} * 2);

  @include media-breakpoint-up(md) {
    padding-bottom: calc(#{$section-spacing-tablet / 3} * 2);
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: calc(#{$section-spacing-desktop / 3} * 2);
  }
}

.section-pb-md {
  @include media-breakpoint-up(md) {
    padding-bottom: $section-spacing-tablet !important;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $section-spacing-desktop !important;
  }
}

/**
 * Section Margin
 */

/* top */
.section-mt--012 {
  margin-top: calc(#{$section-spacing-mobile / 8});

  @include media-breakpoint-up(md) {
    margin-top: calc(#{$section-spacing-tablet / 8});
  }

  @include media-breakpoint-up(lg) {
    margin-top: calc(#{$section-spacing-desktop / 8});
  }
}

.section-mt--025 {
  margin-top: calc(#{$section-spacing-mobile / 4});

  @include media-breakpoint-up(md) {
    margin-top: calc(#{$section-spacing-tablet / 4});
  }

  @include media-breakpoint-up(lg) {
    margin-top: calc(#{$section-spacing-desktop / 4});
  }
}

.section-mt--033 {
  margin-top: calc(#{$section-spacing-mobile / 3});

  @include media-breakpoint-up(md) {
    margin-top: calc(#{$section-spacing-tablet / 3});
  }

  @include media-breakpoint-up(lg) {
    margin-top: calc(#{$section-spacing-desktop / 3});
  }
}

.section-mt--050 {
  margin-top: calc(#{$section-spacing-mobile / 2});

  @include media-breakpoint-up(md) {
    margin-top: calc(#{$section-spacing-tablet / 2});
  }

  @include media-breakpoint-up(lg) {
    margin-top: calc(#{$section-spacing-desktop / 2});
  }
}

.section-mt--066 {
  margin-top: calc(#{$section-spacing-mobile / 3} * 2);

  @include media-breakpoint-up(md) {
    margin-top: calc(#{$section-spacing-tablet / 3} * 2);
  }

  @include media-breakpoint-up(lg) {
    margin-top: calc(#{$section-spacing-desktop / 3} * 2);
  }
}

.section-mt {
  margin-top: $section-spacing-mobile;

  @include media-breakpoint-up(md) {
    margin-top: $section-spacing-tablet;
  }

  @include media-breakpoint-up(lg) {
    margin-top: $section-spacing-desktop;
  }
}

/* bottom */
.section-mb--012 {
  margin-bottom: calc(#{$section-spacing-mobile / 8});

  @include media-breakpoint-up(md) {
    margin-bottom: calc(#{$section-spacing-tablet / 8});
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: calc(#{$section-spacing-desktop / 8});
  }
}

.section-mb--025 {
  margin-bottom: calc(#{$section-spacing-mobile / 4});

  @include media-breakpoint-up(md) {
    margin-bottom: calc(#{$section-spacing-tablet / 4});
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: calc(#{$section-spacing-desktop / 4});
  }
}

.section-mb--033 {
  margin-bottom: calc(#{$section-spacing-mobile / 3});

  @include media-breakpoint-up(md) {
    margin-bottom: calc(#{$section-spacing-tablet / 3});
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: calc(#{$section-spacing-desktop / 3});
  }
}

.section-mb--050 {
  margin-bottom: calc(#{$section-spacing-mobile / 2});

  @include media-breakpoint-up(md) {
    margin-bottom: calc(#{$section-spacing-tablet / 2});
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: calc(#{$section-spacing-desktop / 2});
  }
}

.section-mb--066 {
  margin-bottom: calc(#{$section-spacing-mobile / 3} * 2);

  @include media-breakpoint-up(md) {
    margin-bottom: calc(#{$section-spacing-tablet / 3} * 2);
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: calc(#{$section-spacing-desktop / 3} * 2);
  }
}

.section-mb {
  margin-bottom: $section-spacing-mobile;

  @include media-breakpoint-up(md) {
    margin-bottom: $section-spacing-tablet;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $section-spacing-desktop;
  }
}

/* left */
.section-ml--025 {
  margin-left: calc(#{$section-spacing-mobile / 4});

  @include media-breakpoint-up(md) {
    margin-left: calc(#{$section-spacing-tablet / 4});
  }

  @include media-breakpoint-up(lg) {
    margin-left: calc(#{$section-spacing-desktop / 4});
  }
}

.section-ml--033 {
  margin-left: calc(#{$section-spacing-mobile / 3});

  @include media-breakpoint-up(md) {
    margin-left: calc(#{$section-spacing-tablet / 3});
  }

  @include media-breakpoint-up(lg) {
    margin-left: calc(#{$section-spacing-desktop / 3});
  }
}

.section-ml--050 {
  margin-left: calc(#{$section-spacing-mobile / 2});

  @include media-breakpoint-up(md) {
    margin-left: calc(#{$section-spacing-tablet / 2});
  }

  @include media-breakpoint-up(lg) {
    margin-left: calc(#{$section-spacing-desktop / 2});
  }
}

.section-ml--066 {
  margin-left: calc(#{$section-spacing-mobile / 3} * 2);

  @include media-breakpoint-up(md) {
    margin-left: calc(#{$section-spacing-tablet / 3} * 2);
  }

  @include media-breakpoint-up(lg) {
    margin-left: calc(#{$section-spacing-desktop / 3} * 2);
  }
}

.section-ml {
  margin-left: $section-spacing-mobile;

  @include media-breakpoint-up(md) {
    margin-left: $section-spacing-tablet;
  }

  @include media-breakpoint-up(lg) {
    margin-left: $section-spacing-desktop;
  }
}

/* right */
.section-mr--025 {
  margin-right: calc(#{$section-spacing-mobile / 4});

  @include media-breakpoint-up(md) {
    margin-right: calc(#{$section-spacing-tablet / 4});
  }

  @include media-breakpoint-up(lg) {
    margin-right: calc(#{$section-spacing-desktop / 4});
  }
}

.section-mr--033 {
  margin-right: calc(#{$section-spacing-mobile / 3});

  @include media-breakpoint-up(md) {
    margin-right: calc(#{$section-spacing-tablet / 3});
  }

  @include media-breakpoint-up(lg) {
    margin-right: calc(#{$section-spacing-desktop / 3});
  }
}

.section-mr--050 {
  margin-right: calc(#{$section-spacing-mobile / 2});

  @include media-breakpoint-up(md) {
    margin-right: calc(#{$section-spacing-tablet / 2});
  }

  @include media-breakpoint-up(lg) {
    margin-right: calc(#{$section-spacing-desktop / 2});
  }
}

.section-mr--066 {
  margin-right: calc(#{$section-spacing-mobile / 3} * 2);

  @include media-breakpoint-up(md) {
    margin-right: calc(#{$section-spacing-tablet / 3} * 2);
  }

  @include media-breakpoint-up(lg) {
    margin-right: calc(#{$section-spacing-desktop / 3} * 2);
  }
}

.section-mr {
  margin-right: $section-spacing-mobile;

  @include media-breakpoint-up(md) {
    margin-right: $section-spacing-tablet;
  }

  @include media-breakpoint-up(lg) {
    margin-right: $section-spacing-desktop;
  }
}
