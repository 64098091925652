/**
* Fonts
*/
@mixin font-family-asap {
  font-family: "Asap", sans-serif;
}

@mixin font-family-montserrat {
  font-family: "Montserrat", sans-serif;
}

@mixin font-family-firasans {
  font-family: "Fira Sans", sans-serif;
}

@mixin font-family-opensans {
  font-family: "Open Sans", sans-serif;
}

@mixin font-family-worksans {
  font-family: "Work Sans", sans-serif;
}

@mixin font-family-poppins {
  font-family: "Poppins", sans-serif;
}

@mixin font-family-ptserif {
  font-family: "PT Serif", serif;
}

@mixin font-family-sourcecodepro {
  font-family: "Source Code Pro", monospace;
}

@mixin text-bold {
  font-weight: 700;
}

@mixin text-semi-bold {
  font-weight: 600;
}

@mixin text-medium {
  font-weight: 500;
}

@mixin text-regular {
  font-weight: 400;
}

@mixin text-light {
  font-weight: 400;
}

@function calculate-rem($size) {
  $remSize: $size / 16px;

  @return #{$remSize}rem;
}

@mixin font-size($size) {
  font-size: $size; //Fallback in px
  font-size: calculate-rem($size);
}

@function calculate-lineheight($font-size, $height) {
  @return $height / $font-size;
}

/**
* Placeholder
*/
@mixin placeholder-color($placeholder-color) {
  &::placeholder,
  &::-webkit-input-placeholder,
  &::-ms-input-placeholder,
  &::-moz-placeholder,
  &:-moz-placeholder {
    color: $placeholder-color;
  }
}

/**
 * Link
 */
@mixin link-color($normal-color, $hover-color) {
  color: $normal-color !important;

  &:hover {
    color: $hover-color !important;
  }
}

/**
 * Grid
 */
@mixin grid-template-cols($cols:1) {
  @if $cols == 1 {
    grid-template-columns: repeat(auto-fill, minmax(88%, 1fr));
  }

  @else if $cols == 2 {
    grid-template-columns: repeat(auto-fill, minmax(44%, 1fr));
  }

  @else if $cols == 3 {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }

  @else if $cols == 4 {
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
  }
}

@mixin grid-item($dimension:"width", $cols:2) {
  @if $dimension == "width" {
    grid-column-end: span $cols;

    &::after {
      padding-bottom: 50%;
    }
  }

  @else if $dimension == "height" {
    grid-row-end: span $cols;
    height: 100%;

    &::after {
      padding-bottom: unset;
    }
  }
}

/**
 * Buttons
 */
@mixin btn-hover($shadow-color, $text-color) {
  @media screen and (min-width: 992px) {
    box-shadow: inset 0 0 0 0 $shadow-color;
    transition: all 1.3s ease;
  }

  &:hover {
    background-color: $shadow-color;
    color: $text-color;

    @media screen and (min-width: 992px) {
      background-color: unset;
      box-shadow: inset -900px 0 0 0 $shadow-color;
    }
  }
}

@mixin arrowTransform( $angle, $x: 0, $y: 0) {
  i:first-child {
    transform: translate($x, $y) rotate($angle);
  }

  i:last-child {
    transform: translate($x, -$y) rotate(-$angle);
  }
}

/**
 *CSS Triangles Mixin
 */
@mixin css-triangle($direction, $color, $height, $width) {
  // Base styles for all triangles
  border-style: solid;
  height: 0;
  width: 0;
  // Cardinal Directions - can't be scalene this way
  @if ($direction == "top") {
    border-color: transparent transparent $color transparent;
    border-width: 0 ($width/2) $height ($width/2);
  }

  @if ($direction == "bottom") {
    border-color: $color transparent transparent transparent;
    border-width: $height ($width/2) 0 ($width/2);
  }

  @if ($direction == "right") {
    border-color: transparent transparent transparent $color;
    border-width: ($height/2) 0 ($height/2) $width;
  }

  @if ($direction == "left") {
    border-color: transparent $color transparent transparent;
    border-width: ($height/2) $width ($height/2) 0;
  }

  // Ordinal Directions - can be scalene this way!
  @if ($direction == "top-left") {
    border-color: $color transparent transparent transparent;
    border-width: $height $width 0 0;
  }

  @if ($direction == "top-right") {
    border-color: transparent $color transparent transparent;
    border-width: 0 $width $height 0;
  }

  @if ($direction == "bottom-left") {
    border-color: transparent transparent transparent $color;
    border-width: $height 0 0 $width;
  }

  @if ($direction == "bottom-right") {
    border-color: transparent transparent $color transparent;
    border-width: 0 0 $height $width;
  }
}

/**
 * Backgrounds
 */
@mixin bg-url($url, $url2x: false, $webp1x: false, $webp2x: false) {
  background-image: url($url);

  @if $webp1x {
    .webp & {
      background-image: url($webp1x);
    }
  }

  @if $url2x {
    @media
      screen and (-webkit-min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url($url2x);

      @if $webp2x {
        .webp & {
          background-image: url($webp2x);
        }
      }
    }
  }
}
