/**
* .owl-carousel
**/
//.owl-carousel {
//  .owl-stage,
//  .owl-item {
//    width: 100% !important;
//  }
//}

.owl-dot span {
  border: 1px solid theme-color("grey");
}

.owl-carousel--h100 {
  position: relative;
  height: 100%;

  div:not(.owl-controls):not(.hero__content) {
    height: 100%;
  }
}

.owl-carousel--h045 {
  position: relative;
  height: 45vh;

  div:not(.owl-controls):not(.hero__content) {
    height: 100%;
  }
}
