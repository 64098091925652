.testimonial-item__img-box {
  width: 9rem;
  min-width: 9rem;
  height: 9rem;
}

.testimonial-item__img {
  border: 4px solid $white;
  border-radius: 50%;
}

.testimonial-item__text-box {
  @include media-breakpoint-up(md) {
    margin-left: calc(#{$section-spacing-mobile / 2});

    @include media-breakpoint-up(md) {
      margin-left: calc(#{$section-spacing-tablet / 2});
    }

    @include media-breakpoint-up(lg) {
      margin-left: calc(#{$section-spacing-desktop / 2});
    }
  }
}

.testimonial-item__profile {
  font-style: normal;
}
