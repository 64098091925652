/*****
* Front Page
******/

//hero

//about

//analyze
.stage__card-box {
  @include media-breakpoint-up(lg) {
    max-width: 20%;
  }
}

.stage__card-item {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  line-height: 2;
  margin: 0 auto;
}

.stage__card-content {
  margin-top: -2rem;
  border: 1px solid gray("200");

  @include media-breakpoint-up(lg) {
    min-height: calc(100% - 2rem);
  }
}

.stage__card-title {
  margin-top: 2rem;
}

//solutions
.frontpage-solutions__img-box {
  margin-bottom: 1.5rem;

  svg {
    fill: theme-color("primary");
  }
}

.frontpage-solutions-item {
  padding-bottom: $section-spacing-mobile;

  &:last-of-type {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
}

.solution-item__img {
  max-height: 4.5rem;
}

//news
.frontpage-news__posts-list {
  border-bottom: 1px solid gray("200");
}

.frontpage-news__post {
  margin-bottom: 3rem;
}

.frontpage-news__post-meta {
  display: block;
  margin-bottom: 1rem;
}

.frontpage-news__post-title {
  transition: $transition-all-faster;

  &:hover {
    color: theme-color("red-cimbre");
  }
}

.frontpage-news__post-date {
  @include font-size(14px);
}

//contact
.frontpage-contact-form__address {
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.frontpage-contact-form__map-box {
  @include media-breakpoint-up(lg) {
    min-height: 24.5rem;
  }
}

.frontpage-contact-form__box {
  margin-bottom: $section-spacing-mobile;

  @include media-breakpoint-up(lg) {
    margin-bottom: unset;
  }
}

/*****
* Blog
******/
