/**
* Boostrap nav
*/
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: center;
}

/**
* Hamburgers
*/
.hamburger.is-active:hover,
.hamburger:hover {
  opacity: 1;
}

/**
* Menu
*/
.menu {
  @include font-family-opensans;
}

.menu__icon {
  height: auto;
}

.menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

/**
* Submenu
*/
.sub-menu {
  display: none;
  float: none;
  position: fixed;
  z-index: 9999;
  padding: 1.5rem 0;
  opacity: 0;
  background: white;
  transition: opacity 0.5s ease;
}

/**
* menu-item
**/
.menu-item {
  a {
    opacity: 1;
    padding-bottom: 0.125rem;
    transition: $transition-all-faster;

    &::after {
      content: "";
      opacity: 1;
      display: block;
      margin: auto;
      width: 0;
      height: 2px;
      background-color: transparent;
      transition: width ease 0.3s, opacity ease 0.3s;
    }

    &:hover {
      opacity: 0.7;

      &::after {
        opacity: 0.7;
        background-color: white;
        width: 100%;
      }
    }
  }
}

.menu-item.active {
  a {
    opacity: 1;

    &::after {
      opacity: 1;
      background-color: white;
      width: 100%;
    }
  }
}

/**
* Menu align
*/
.menu--left {
  float: left;
  margin-right: 2.5rem;

  > li:last-of-type {
    padding-left: 0;
  }
}

.menu--center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.menu--right {
  float: right;
  margin-left: 2.5rem;

  .menu__list > li:last-of-type {
    padding-right: 0;

    a {
      padding-right: 0;
    }
  }
}

/* stylelint-disable no-descending-specificity */

/**
 * Mobile Menu
 **/
.menu-mobile__wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 100;

  &.is-active {
    display: block;
  }
}

.menu-mobile__content {
  opacity: 0;
  height: calc((100% - (2 * #{$header-mobile--height})) + 2rem);
  margin-top: $header-mobile--height;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.menu-mobile__list {
  .menu-item {
    @include font-size(32px);

    line-height: 2;

    &.active {
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      line-height: 4;
    }
  }
}

.menu-mobile__footer {
  opacity: 0;
  height: $header-mobile--height;
}

.menu-mobile__copyright {
  justify-content: flex-start !important;
}

/*********************
* Mobile Social Nav
*********************/
.menu-mobile__social-nav .menu-item {
  margin-right: 2rem;

  &:last-of-type {
    margin-right: 0;
  }

  a {
    display: block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    color: white;
    opacity: 1;
    background-color: black;
    border-radius: 50%;
    text-align: center;

    &::after {
      content: unset;
    }
  }
}

/**
 * Menu's Margin
 **/
.primary-menu,
.menu-portfolio-categories {
  .menu-item {
    margin-left: 1rem;
    margin-right: 1rem;

    &:last-of-type {
      margin-right: unset;
    }
  }
}

/*********
* Nav Tabs
**********/
.nav-tabs {
  border: unset;

  .nav-item {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: -1px;
    }
  }

  .nav-link {
    border: unset;
    border-color: gray("200");
    border-radius: unset !important;
    border-bottom: 1px solid gray("200");
    opacity: 0.6;
    color: unset;
    transition: $transition-all-faster;

    @include media-breakpoint-up(md) {
      border-bottom: unset;
      border-right: 1px solid gray("200");
    }

    &:hover {
      opacity: 1;
    }
  }

  .nav-link.active {
    color: unset;
    opacity: 1;
    border-color: black;
    border-bottom: 2px solid;

    @include media-breakpoint-up(md) {
      border-bottom: unset;
      border-right: 2px solid;
    }
  }
}

/* stylelint-enable no-descending-specificity */
