/**
 * Bootstrap
 */

// Import Bootstrap functions
@import "~bootstrap/scss/functions";

// Import Bootstrap variables
@import "~bootstrap/scss/variables";

$theme-colors: (
  "primary": #002639,
  "secondary": #ed365e,
  "tertiary": #0d102c,
  "danger": #f00,
  "sucess": #0f0,
  "gray-map": #e5e3df,
  "hero": #cfd8dc,
  "gray": #90a4ae,
  "gray-dark": #343a40,
  "gray-light": #f8f9fa,
  "blue-gray": #37474f,
  "red-cimbre": #b12029
);

// Bootstrap navbar fix (https://git.io/fADqW)
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

/**
 * Owl Carousel
 */

// dots

/*
$dot-width:				18px;
$dot-height:			18px;
$dot-rounded:			30px !default;
$dot-margin: 			5px 7px !default;
$dot-background:		transparent;
$dot-background-active:	theme-color("dust");
*/

//Fix buil error - Webpack npm error #2107 (https://github.com/OwlCarousel2/OwlCarousel2/issues/2107)
$owl-image-path: "~owl.carousel/src/img/";

/**
 * Transitions
 */
$hover: all 0.2s ease-in;
$slide: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);

$transition-all-fast: all 800ms ease;
$transition-all-faster: all 500ms ease;
$transition-all-normal: all 1s ease;
$transition-all-slow: all 2s ease;
$transition-all-slower: all 3s ease;

/**
 * Header
 */
$header-mobile--height: 5rem;
$header--height: 5rem;
$header-line1--height: 5rem;
$header-line2--height: 0;
$header-shadow--height: 0.24rem;

/**
 * Block colors
 */
$header-background-color: $white;
$hero-background-color: theme-color("hero");
$hero-background-svg-placeholder: url(data:image/svg+xml;base64);
$hero-background-opacity: rgba(0, 0, 0, 0.75);
$footer-background-color: black;

/**
 * Hamburger icon
 */
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: #fff;
$hamburger-types: (squeeze);

/**
 * Section spacing
 */
$section-spacing-mobile: 2.5rem;
$section-spacing-tablet: 3.5rem;
$section-spacing-desktop: 5rem;
