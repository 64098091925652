/**
* Global
**/
body,
html {
  max-width: 100%;
}

body {
  @include font-family-opensans;
  @include font-size(16px);
  @include text-regular;

  padding: 0;
  margin: 0;
  letter-spacing: 0;
  color: theme-color("blue-gray");
  background: white;
  line-height: 1.6;
  overflow-x: hidden !important;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: unset;
    text-decoration: none;
    outline: none;

    &:hover {
      color: unset;
      text-decoration: none;
    }
  }

  address,
  blockquote,
  figure,
  ul {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  button,
  button:active,
  button:focus {
    /* :not(:focus-visible) { /* stylelint-disable-line selector-pseudo-class-no-unknown */
    outline: none;
  }
}

/**
 * Link
 */
.link--hover {
  padding-bottom: 0.125rem;
  transition: $transition-all-faster;
  display: inline-block;

  &::after {
    content: "";
    display: block;
    //margin: auto;
    width: 0;
    height: 1px;
    background-color: transparent;
    transition: width ease 0.5s;
  }

  &:hover {
    color: theme-color("secondary");

    &::after {
      background-color: theme-color("secondary");
      width: 100%;
    }
  }
}

/**
* Backgrounds Colors
*/
.bkg--gray-map {
  background-color: theme-color("gray-map");
}

.bkg--transparent {
  background-color: transparent;
}

.bkg--white {
  background-color: white;
}

.bkg--black {
  background-color: black;
}

.bkg--primary {
  background-color: theme-color("primary");
}

.bkg--secondary {
  background-color: theme-color("secondary");
}

.bkg--tertiary {
  background-color: theme-color("tertiary");
}

.bkg--placeholder {
  background-color: theme-color("gray-dark");
}

.bkg--gray-light {
  background-color: theme-color("gray-light");
}

.bkg--gray-400 {
  background-color: gray("400");
}

/**
 * Titles
 */
.post__content h1,
.post__content h2,
.post__content h3,
.post__content h4,
.title {
  @include font-family-montserrat;
  @include text-bold;

  color: black;
  line-height: 1.3;
  margin-bottom: 0;
}

//h1
.post__content h1,
.title--xlarge {
  font-size: 2.25rem;

  @media screen and (min-width: 411px) {
    font-size: 2.625rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
}

//h2
.post__content h2,
.title--large {
  font-size: 2.5rem;
}

//h3
.post__content h3,
.title--medium {
  font-size: 2rem;
}

//h4
.post__content h4,
.title--small {
  font-size: 1.25rem;
}

/**
* Texts
*/
.text {
  @include font-family-asap;
  @include text-regular;
}

.text--shadow {
  text-shadow: 0.125rem 0.125rem rgba(0, 0, 0, 0.2);
}

//Body Large
.text--xlarge {
  @include font-size(28px);

  line-height: calculate-lineheight(32px, 40px);
}

//Body Medium
.text--large {
  @include font-size(21px);

  line-height: calculate-lineheight(21px, 30px);
}

//Body Small
.text--medium {
  @include font-size(18px);

  line-height: 1.7;
}

//Body Extra Small
.text--small {
  @include font-size(14px);

  line-height: calculate-lineheight(14px, 21px);
}

/**
* Quotes
*/
.text--quote {
  @include font-family-firasans;
  @include text-medium;
  @include font-size(44px);

  line-height: calculate-lineheight(44px, 52px);
}

/**
* Cite
**/
.text--cite {
  @include font-family-asap;
  @include text-regular;
  @include font-size(14px);

  line-height: 1;
  font-style: normal;
}

/**
* Text Color
**/
.text--primary {
  color: theme-color("primary");
}

.text--white {
  color: white;
}

.text--black {
  color: black;
}

.text--gray-dark {
  color: theme-color("gray-dark");
}

.text--gray-light {
  color: theme-color("gray-light");
}

/**
 * Text Weights
 **/

/*
 .text--bold {
  font-weight: 700;
}

.text--semi-bold {
  font-weight: 600;
}

.text--medium {
  font-weight: 500;
}

.text--normal {
  font-weight: 400;
}

.text--light {
  font-weight: 300;
}
*/

/************
* Text Blocks
*************/
.text-block__title,
.text-block p {
  margin-bottom: 1rem;
}

.text-block p:last-of-type {
  margin-bottom: 0;
}

/**
* line-height
*/

.lh--1 {
  line-height: 1;
}

.lh--12 {
  line-height: 1.2;
}

.lh--15 {
  line-height: 1.5;
}

.lh--2 {
  line-height: 2;
}

/****************
* Line Separator
****************/
.line-separator {
  height: 1px;
  border-width: 0;
  margin: 1.5rem 0;

  @include media-breakpoint-up(lg) {
    margin: 3rem 0;
  }
}

//.line-separator--gray-light {
//  border-color: color("gray-light");
//}
//
//.line-separator--gray-dark {
//  border-color: color("gray-dark");
//}

/*********
* Cursors
*********/
.cursor--zoom-in {
  cursor: zoom-in;
}

.cursor--pointer {
  cursor: pointer;
}

/**
* Transitions
*/
.no-transition,
.no-transition-reset {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.transition--fast {
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
}

.transition--faster {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.transition--slow {
  -webkit-transition-duration: 2s;
  transition-duration: 2s;
}

.transition--slower {
  -webkit-transition-duration: 3s;
  transition-duration: 3s;
}

/**
* Animate.css
**/
@-webkit-keyframes fadeinleftsmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeinleftsmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeinleftsmall {
  -webkit-animation-name: fadeinleftsmall;
  animation-name: fadeinleftsmall;
}

@-webkit-keyframes fadeinrightsmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeinrightsmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeinrightsmall {
  -webkit-animation-name: fadeinrightsmall;
  animation-name: fadeinrightsmall;
}

/**
* Scroll
**/
.no-scroll {
  overflow: hidden;
}

/**
 * Triangles
 */

.triangle-right {
  &::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 50px 86.6px;
    border-color: transparent transparent transparent #6980fe;
  }
}

.arrow-caret-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 50px 60px;
  border-color: transparent transparent transparent #007bff;

  /*
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-bottom-width: 100px;
  border-top-width: 100px;
  border-left: 100px solid black;
  */
}

/**
* Shadows
**/
.box--shadow {
  box-shadow: 0 0.25rem 0.24rem 0 rgba(0, 0, 0, 0.16);
}
