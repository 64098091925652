.hero {
  position: relative;
  width: 100%;
}

.hero--h025 {
  height: 25vh;
}

.hero--h033 {
  height: 33.33vh;
}

.hero--h040 {
  height: 40vh;
}

.hero--h045 {
  height: 45vh;
}

.hero--h050 {
  height: 50vh;
}

.hero--h075 {
  height: 75vh;
}

.hero--h100 {
  height: 100vh;
}

.hero--parallax {
  position: fixed !important;
}

.hero__background {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $hero-background-color;

  /*
  &::after {
    width: 100%;
    height: 100%;
    background-color: $hero-background-color;
    opacity: $hero-background-opacity;
  }
  */
}

.hero__content {
  padding-top: $header-mobile--height;
  z-index: 2;

  @media (min-width: 540px) {
    margin-left: 4.333%;
  }

  @media (min-width: 992px) {
    padding-top: $header--height;
    margin-left: unset;
  }
}

.hero_subtitle {
  line-height: 1.6;
}
