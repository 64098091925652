/**
* Font Awesome
*/

//Icons
%fa-globe-americas {
  position: relative;

  &::before {
    content: url(../images/icon-globe.svg);
    position: absolute;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    left: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: inherit;
  }
}

/**
* Heart
**/

/*
.icon-heart {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 0 0 0 1px;
  background-color: #af070b;
  transform: rotate(-45deg);

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: inherit;
  }

  &::before {
    top: -5px;
    left: 0;
  }

  &::after {
    top: 0;
    right: -5px;
  }

  &.--pulse {
    animation: pulse 1.5s infinite;
  }
}

@keyframes pulse {
  0%,
  20% {
    transform: rotate(-45deg) scale(1);
  }

  30% {
    transform: rotate(-45deg) scale(1.2);
  }

  50% {
    transform: rotate(-45deg) scale(1);
  }

  60% {
    transform: rotate(-45deg) scale(1.1);
  }

  70%,
  100% {
    transform: rotate(-45deg) scale(1);
  }
}
*/

.--pulse {
  animation: pulse2 1.5s infinite;
}

@keyframes pulse2 {
  0%,
  20% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.1);
  }

  70%,
  100% {
    transform: scale(1);
  }
}

/**
 * Circle
 **/
.icon-circle {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;

  &::after {
    content: "";
    position: absolute;
    background-color: black;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translateX(-50%);
  }
}
