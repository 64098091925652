/**
* Footer
*/

.footer__title {
  line-height: 1.2;
  margin-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    height: 2.25rem;
    line-height: 2.25rem;
  }
}

.footer__text {
  @include font-size(15px);
}

.footer__description-box,
.footer__nav-box {
  margin-bottom: 2.5rem;
}

.footer__contact-inner {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: unset;
  }
}

.footer__address,
.footer__phones,
.footer__email {
  margin-bottom: 0.5rem;
}

.footer__nav-box {
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up(lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

//LOGO
.footer__logo {
  display: flex;
  height: 2rem;

  .logo__inner {
    width: 9rem;
    float: left;

    @include media-breakpoint-up(lg) {
      width: 6rem;
      height: 3rem;
    }
  }
}

//E-MAIL

//LINKS
.footer-menu {
  .menu-item {
    margin-left: 1rem;
    margin-right: 1rem;

    a::after {
      background-color: transparent;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

//SOCIAL NAV
.footer__social-nav-box {
  margin-top: 2rem;
}

.footer__social-nav {
  .menu-item {
    margin-right: 2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .menu-item a {
    display: block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    color: black;
    opacity: 1;
    background-color: white;
    border-radius: 50%;
    text-align: center;

    &::after {
      content: unset;
    }
  }
}

//COPYRIGHT
.copyright__text-box {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.copyright__text {
  padding-right: 0.375em;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(lg) {
    justify-content: start;
    flex-wrap: nowrap;
  }
}

.copyright-text__dash {
  display: none;

  @media screen and (min-width: 467px) {
    display: inline-block;
  }
}
