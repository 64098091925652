.page-numbers {
  border: 1px solid theme-color("dust");
  padding: 0 0.375rem;
  transition: $transition-all-faster;

  &:hover {
    color: theme-color("green");
    border-color: theme-color("green");
  }

  &.current,
  &.current:hover {
    color: theme-color("gray-dark");
    background-color: theme-color("sand");
    border-color: theme-color("dust");
  }
}
