/**
* breadcrumbss
*/
.breadcrumbs__content {
  border-bottom: theme-color("gray-light");
}

.breadcrumbs__link {
  padding-bottom: 0.125rem;
  transition: $transition-all-faster;
  display: inline-block;

  &::after {
    content: "";
    display: block;
    margin: auto;
    width: 0;
    height: 1px;
    background-color: transparent;
    transition: width ease 0.3s;
  }

  &:hover {
    color: theme-color("green");

    &::after {
      background-color: theme-color("green");
      width: 100%;
    }
  }
}
