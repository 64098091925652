form,
.form__field,
.search__field,
.form__field--textarea,
.wpcf7-form-control-wrap {
  width: 100%;
}

/** Search form */
.search-form__box {
  outline: 1px solid theme-color("gray");

  &:focus {
    outline: 1px solid theme-color("primary");
  }
}

.search__field {
  @include placeholder-color(theme-color("red-cimbre"));

  background-color: transparent;
  padding: 0.625rem;
  border: 0;
  outline: none;

  &:focus {
    .search-form__box & {
      outline: 1px solid theme-color("primary");
    }
  }
}

/** Contact form */
.form__cta-text {
  padding-bottom: 2.5rem;
}

.form__group {
  display: flex;
  margin-bottom: 1rem;
}

.form__label {
  @include font-size(21px);

  color: theme-color("primary");
  padding-bottom: 0.5rem;
}

.form__field--textarea {
  height: 10rem;
}

.form__field,
.form__field--textarea {
  @include placeholder-color(theme-color("red-cimbre"));
  @include font-size(15px);

  color: theme-color("primary");
  border: 0;
  outline: 1px solid theme-color("gray");
  background-color: transparent;
  line-height: 2;
  padding: 13px 19px;
  margin-bottom: 22px;
  transition: $transition-all-fast;

  &:focus {
    color: theme-color("dark-gray");
    outline-color: theme-color("primary");

    //&:not(:focus-visible) { /* stylelint-disable-line selector-pseudo-class-no-unknown */
    //  outline: none !important;
    //}
  }
}

.form__msg-text {
  margin-top: auto;
  margin-bottom: auto;
}

.ajax-loader,
.wpcf7-validation-errors,
.wpcf7-not-valid-tip {
  display: none !important;
}

input[aria-invalid="true"],
select[aria-invalid="true"] {
  outline: 1px solid theme-color("danger");
}

div.wpcf7-mail-sent-ok {
  outline: 1px solid theme-color("success");
}
