/*******
* Images
********/
.embed__box,
.img__box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: auto;
  max-width: 100%;
}

.img__box-link {
  display: inline-grid;
}

.img__box::after {
  content: "";
  display: block;
}

.img__box--square::after {
  padding-bottom: 100%;
}

.img__box--wide::after {
  padding-bottom: 56.25%;
}

.img__box--32::after {
  padding-bottom: 66.66%;
}

.img__box--std::after {
  padding-bottom: 75%;
}

.img__box--w2::after {
  padding-bottom: 50%;
}

.img__box-md--w2::after {
  @include media-breakpoint-up(md) {
    padding-bottom: 50%;
  }
}

.img__box--h2::after {
  padding-bottom: 200%;
}

.img-center,
.img-center-bottom,
.picture-img--center img,
.embed__box iframe,
.embed__box object,
.embed__box embed {
  position: absolute;
  width: 100%;
}

.img-center,
.picture-img--center img,
.embed__box iframe,
.embed__box object,
.embed__box embed {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  object-fit: cover;
}

.img-center-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;
}

.img-center--zoom {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width ease 0.5s, height ease 0.5s;
  object-fit: cover;

  &:hover {
    width: 105%;
    height: 105%;
  }
}

//Filters
.img-filter--grayscale-onhover {
  &:hover {
    filter: grayscale(1);
  }
}
