/*******************
* News Post
*******************/
.news__post {
  margin-bottom: $section-spacing-mobile;

  @include media-breakpoint-up(lg) {
    margin-bottom: $section-spacing-desktop;
  }
}

.news__post-title {
  font-size: 1.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
}

.news__post-info {
  display: block;
  margin-bottom: 0.5rem;
}

.news-post-item__img-box {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3rem;
  }
}

/*******************
* Nextcom Works
*******************/
.single-nextcom_works {
  /* attributes */
  .work__attribute {
    display: table;
    table-layout: fixed;
    margin-bottom: 0.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .work__label {
    min-width: 6rem;
    display: table-cell;

    @include media-breakpoint-up(lg) {
      min-width: unset;
      padding-right: 2rem;
    }
  }

  /* gallery */
  .work__gallery-grid {
    .container-grid {
      grid-gap: 1rem;
      grid-template-rows: repeat(1, 1fr);
      grid-auto-flow: dense;

      /* 1 col */
      grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));

      /* 2 cols */
      @include media-breakpoint-up(md) {
        grid-gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
      }

      /* 3 cols */
      @include media-breakpoint-up(lg) {
        grid-gap: 2rem;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      }

      /* 3 cols */
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      }
    }
  }

  .gallery-item__box {
    border: 1px solid gray("200");
  }
}

/* article */
.nextcom_works {
  margin-top: $header-mobile--height;

  @include media-breakpoint-up(lg) {
    margin-top: $header--height;
  }
}

/*******************
* Nextcom Solutions
*******************/

/* solution */
.solucao-solution__text-title,
.solucao-solution__desc p {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3rem;
  }
}

.solucao-solution__desc p:last-of-type {
  margin-bottom: 0;
}

/* catchphrase */
.solucao-catchphrase__author {
  @include font-size(14px);

  margin-top: 1rem;
}

/***********************
* Solution: Estratégia
***********************/
.single-nextcom_solutions.estrategia {
  .img__logo {
    filter: drop-shadow(1px 1px 1px rgba(0, 38, 57, 0.35));
  }
}
