#inicio {
  position: absolute;
  top: 0;
  height: 0;
  opacity: 0;
}

/**
* Logo
**/
.header__logo {
  width: 6rem;
  float: left;
}

.logo__link {
  @include font-size(22px);

  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
}

.logo__img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

/**
* Header
**/
.header,
.header__inner,
.header__logo,
.header .menu__list {
  height: $header-mobile--height;
  line-height: $header-mobile--height;
  z-index: 100;

  @media (min-width: 992px) {
    height: $header--height;
    line-height: $header--height;
  }
}

.header__nav-bar {
  border-bottom: 1px solid white;
}

.header {
  overflow: hidden;

  .logo__link {
    transition: opacity 1s ease, transform 0.5s ease;
  }

  .logo--white {
    opacity: 1;
    transform: translateY(0);
  }

  .logo--black {
    opacity: 0;
    transform: translateY(5rem);
  }

  .menu__list {
    align-items: center;
    line-height: 1.6;
  }

  &.--sticky {
    background-color: $header-background-color;
    border-bottom: 1px solid gray("200");
    transition: background-color ease 0.3s;

    /* line */
    .header-inner > .nav-bar {
      border-bottom: unset;
    }

    /* menu-mobile icon */
    .hamburger .hamburger-inner,
    .hamburger .hamburger-inner::before,
    .hamburger .hamburger-inner::after,
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::after,
    .hamburger.is-active .hamburger-inner::before {
      background-color: theme-color("primary") !important;
    }
  }

  &.--swap {
    .logo--white {
      opacity: 0;
      transform: translateY(-5rem);
    }

    .logo--black {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (min-width: 992px) {
    height: $header--height;
    line-height: $header--height;

    &.--sticky {
      .menu {
        color: black !important;

        .menu-item a::after {
          background-color: black;
        }
      }
    }
  }
}
