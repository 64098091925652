.sidebar__content {
  .cat-item {
    border-bottom: 1px solid theme-color("geyser");
    padding: 0.5rem 0 1rem;

    &:first-of-type {
      padding: 0 0 1rem;
    }

    &::before {
      content: "";
      display: inline-block;
      margin-right: 0.5rem;

      @include css-triangle("right", theme-color("orient"), 12px, 8px);
    }
  }

  .post-item {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
